import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { useTranslation } from 'locales/i18n';
import { useSearchParams } from 'react-router-dom';
import { Paths } from 'routes/paths';

export function SecuredLabNavSection() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const labToken = searchParams.get('labToken');

  const navItems: NavListProps[] = [
    {
      title: t('navbar.trades'),
      path: '/securedaccess',
      icon: <NavIcon name="ic_booking" />,
      children: [
        {
          title: t('navbar.dashboard'),
          path: `${Paths.LabSecuredAccessDashboard}?labToken=${labToken}`,
          rightIcon: <NavIcon name="ic_lock" />,
        },
        {
          title: t('navbar.labTradeInstances'),
          path: `${Paths.LabSecuredAccessTrades}?labToken=${labToken}`,
        },
      ],
    },
    {
      title: 'Aide',
      icon: <NavIcon name="ic_question" />,
      path: Paths.IntercomFAQ,
    },
  ];

  return <NavSection navItems={navItems} />;
}
