import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import Logo from 'components/Logo';
import { PageLayoutCancelLink } from 'components/link/PageLayoutCancelLink';
import { includes } from 'lodash';
import { Outlet, useLocation } from 'react-router-dom';
import { Paths } from 'routes/paths';

const HeaderStyle = styled('header')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const OnboardingLayout = () => {
  const { pathname } = useLocation();

  const shouldSignOut = includes(
    [
      Paths.OnboardingPharmacistOrLab,
      Paths.OnboardingSearchPharmacy,
      Paths.OnboardingVerifyEmail,
      Paths.OnboardingVerifyAccount,
    ],
    pathname
  );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <HeaderStyle>
        <Stack flexDirection="row">
          <Logo />
          <Box style={{ alignSelf: 'flex-end', position: 'relative', bottom: 7 }}>
            <PageLayoutCancelLink
              shouldSignOut={shouldSignOut}
              onSignOutNavigateTo={Paths.LoginPhoneNumber}
            />
          </Box>
        </Stack>
      </HeaderStyle>
      <Outlet />
    </Box>
  );
};

export default OnboardingLayout;
