import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { exists as i18nExists, t } from 'i18next';
import { toast } from 'react-toastify';
import { ErrorDescription } from 'types/handled_error';
import { stringUtils } from 'utils/stringUtils';
import { isHandled } from './handledErrors';

export type ApiError = {
  error: string;
  status: string;
  // FKS-1601 Allow for multiple errors in the response
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sub_errors: any;
};

class Api {
  baseURL: string;

  client: AxiosInstance;

  /**
   * constructor
   * Create an instance of the faksApi class.
   *
   * @param {string} baseURL
   */
  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.client = axios.create({
      baseURL,
    });

    this.client.interceptors.response.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        if (
          response.data?.meta === undefined ||
          response.data?.meta.errors === undefined ||
          response.data?.auth_token !== undefined
        ) {
          return process.env.REACT_APP_ENV === 'development'
            ? JSON.parse(
                JSON.stringify(response).replace(
                  /http:\/\/localhost:3000\//gi,
                  baseURL.replace('/v1', '')
                )
              )
            : response;
        } else {
          const { status } = response;

          if (status !== 200) {
            const message = Object.keys(response.data.meta.errors)
              .map((field) => `${field}: ${response.data.meta.errors[field][0]}`)
              .join('\n');

            toast.error(message);
          }

          return response;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any) => {
        if (error.toString() === 'Error: Network Error') {
          toast.error('Oups ! Veuillez vérifier votre connexion puis réessayer');
        } else {
          const errorDescription: ErrorDescription = {
            endpoint: this.getEndpoint(error?.config?.url),
            method: error?.config?.method,
            responseStatus: error?.response?.status,
            error: error?.response?.data?.error,
          };
          if (!isHandled(errorDescription)) {
            const errorCode = error?.response?.data?.error_code;
            const errorDetails: Record<string, string> | undefined =
              error?.response?.data?.error_details;
            if (errorCode && i18nExists(`commonErrors.${errorCode}`)) {
              toast.error(t(`commonErrors.${errorCode}`, errorDetails));
            } else {
              toast.error(
                `${t('generalError.error')} ${errorDescription.responseStatus}.\n ${t(
                  'generalError.contactIfPersist'
                )}`
              );
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  get = async <T>(path: string, config?: AxiosRequestConfig): Promise<T> => {
    const result = await this.client.get<T>(path, config);
    return result.data;
  };

  post = async <T>(path: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const result = await this.client.post<T>(path, data, config);
    return result.data;
  };

  put = async <T>(path: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const result = await this.client.put<T>(path, data, config);
    return result.data;
  };

  patch = async <T>(path: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const result = await this.client.patch<T>(path, data, config);
    return result.data;
  };

  delete = async <T>(path: string, config?: AxiosRequestConfig): Promise<T> => {
    const result = await this.client.delete<T>(path, config);
    return result.data;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData: (data: Record<string, any>, prefix: null | string) => FormData = (
    data,
    prefix = null
  ) => {
    const formData = new FormData();
    try {
      for (const key in data) {
        if (data[key] != null) {
          if (Array.isArray(data[key])) {
            const formKey = prefix == null ? `${key}[]` : `${prefix}[${key}][]`;
            for (const i in data[key]) {
              formData.append(formKey, data[key][i]);
            }
          } else {
            const formKey = prefix == null ? key : `${prefix}[${key}]`;
            const formValue =
              typeof data[key] === 'object' && 'uri' in data[key]
                ? this.formFileData(data[key])
                : data[key];
            formData.append(formKey, formValue);
          }
        }
      }
    } catch (error) {
      throw error;
    }
    return formData;
  };

  getEndpoint = (endpoint: string): string => {
    const parsed_endpoint = endpoint?.split('v2/').pop() || endpoint;
    const path = parsed_endpoint.split('/');
    if (stringUtils.isStringNumerical(path.pop())) return path.join('/');
    return parsed_endpoint;
  };

  formFileData = (file: {
    name: string;
    uri: string;
    type: string;
  }): { name: string; uri: string; type: string } => {
    const match = /\.(\w+)$/.exec(file.uri);
    const type = match ? `image/${match[1]}` : `image`;
    return {
      name: file.name,
      uri: file.uri,
      type: file.type === 'image' ? type : file.type,
    };
  };
}

export const faksApiInstance = new Api(process.env.REACT_APP_API_URL || '');
