import { Box, Divider, Drawer, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'components/Image';
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import { NAVBAR } from 'config';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'locales/i18n';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { selectUserToken } from 'redux/slices/auth';
import { Paths } from 'routes/paths';
import AccountPopover from './AccountPopover';
import { OptImpersonationBanner } from './OptImpersonationBanner';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  children: ReactNode;
};

export const NavbarBase = ({ isOpenSidebar, onCloseSidebar, children }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const authToken = useSelector(selectUserToken);
  const [searchParams] = useSearchParams();
  const labToken = searchParams.get('labToken');
  const accessToken = searchParams.get('access_token');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle sx={{ width: { lg: NAVBAR.DASHBOARD_WIDTH } }}>
      <Drawer
        open={isDesktop || isOpenSidebar}
        onClose={isDesktop ? undefined : onCloseSidebar}
        variant={isDesktop ? 'persistent' : undefined}
        PaperProps={
          isDesktop
            ? {
                sx: {
                  width: NAVBAR.DASHBOARD_WIDTH,
                  borderRightStyle: 'dashed',
                  bgcolor: 'background.default',
                  transition: (theme) =>
                    theme.transitions.create('width', {
                      duration: theme.transitions.duration.standard,
                    }),
                },
              }
            : { sx: { width: NAVBAR.DASHBOARD_WIDTH } }
        }
      >
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          }}
        >
          <Stack spacing={3} sx={{ pt: 3, pb: 2, px: 2.5, flexShrink: 0 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {isDesktop ? (
                <Logo />
              ) : (
                <Image alt="country" src="/assets/logo_faks.ico" sx={{ width: 35, ml: 2, mr: 1 }} />
              )}
            </Stack>
          </Stack>

          {children}

          <Box sx={{ flexGrow: 1 }} />

          <OptImpersonationBanner />

          <Divider />

          <Stack sx={{ p: 2 }}>
            {authToken === null && (labToken !== null || accessToken !== null) ? (
              <Link href={Paths.LoginEmail} alignSelf="center">
                {t('shared.connect')}
              </Link>
            ) : (
              <AccountPopover />
            )}
          </Stack>
        </Scrollbar>
      </Drawer>
    </RootStyle>
  );
};
