import LoadingScreen from 'components/LoadingScreen';
import { OnboardingStepCheck } from 'components/OnboardingStepCheck';
import { useCurrentPharmacy, useCurrentRole, useCurrentUser } from 'hooks/slices';
import { useGetResellerWordingType } from 'hooks/useGetResellerWordingType';
import { useShouldAcceptCgu } from 'hooks/useShouldAcceptCgu';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import OnboarderLayout from 'layouts/OnboarderLayout';
import OnboardingLayout from 'layouts/OnboardingLayout';
import MainLayout from 'layouts/dashboard';
import { isNil } from 'lodash';
import AdminSelloutPanelistGeneratorPage from 'pages/admin/SelloutPanelistGenerator/AdminSelloutPanelistGeneratorPage';
import { AcceptCguPage } from 'pages/shared/AcceptCguPage';
import FromIdentityProvider from 'pages/shared/Login/FromIdentityProvider';
import FromToken from 'pages/shared/Login/FromToken';
import Invitation from 'pages/shared/Login/Invitation/Invitation';
import VerifyCode from 'pages/shared/Login/VerifyCode/VerifyCode';
import VerifyEmail from 'pages/shared/Login/VerifyEmail/VerifyEmail';
import VerifyPhoneNumber from 'pages/shared/Login/VerifyPhoneNumber/VerifyPhoneNumber';
import { ElementType, ReactNode, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import { selectUserToken } from 'redux/slices/auth';
import { isPharmacyRole } from 'utils/rolesUtils';
import { lazyRetry } from 'utils/routesUtils';
import RootNavigate from './RootNavigate';
import { Paths } from './paths';
import {
  canAccessAdminPages,
  canAccessGroupPages,
  canAccessLabPages,
  canAccessOnboarderPages,
  canAccessOnboardingPages,
  canAccessPharmacyPages,
} from './routeUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/pharmacy')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const authToken = useSelector(selectUserToken);
  const [searchParams] = useSearchParams();
  const labToken = searchParams.get('labToken');
  const { pathname } = useLocation();
  const { isLoading: isResellerWordingTypeLoading } = useGetResellerWordingType();

  const currentUser = useCurrentUser();
  const currentRole = useCurrentRole();
  const pharmacy = useCurrentPharmacy();
  const shouldAcceptCguState = useShouldAcceptCgu();

  const displayRouteOnlyIf = (predicate: boolean, component: ReactNode) =>
    predicate ? (
      component
    ) : (
      <Navigate
        to={`/?destination_path=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`}
        replace
      />
    );

  const withSectionOnboardingStepCheck = useCallback(
    (routeObjects: RouteObject[] | undefined): RouteObject[] | undefined =>
      routeObjects?.map((routeObject) => ({
        ...routeObject,
        element: (
          <OnboardingStepCheck
            component={routeObject.element}
            pathname={pathname}
            pharmacy={pharmacy}
            role={currentRole}
            user={currentUser}
          />
        ),
      })),
    [pathname, pharmacy, currentRole, currentUser]
  );

  const shouldRenderGeneralLoader = !!authToken && !currentUser;

  const waitForContextLoadingAnd = (element: ReactNode) => {
    if (
      shouldRenderGeneralLoader ||
      shouldAcceptCguState.isLoading ||
      isResellerWordingTypeLoading
    ) {
      return <LoadingScreen />;
    } else if (shouldAcceptCguState.shouldAcceptCgu) {
      return <AcceptCguPage />;
    }

    return element;
  };

  return useRoutes([
    {
      path: '/',
      element: waitForContextLoadingAnd(<RootNavigate />),
    },
    {
      path: '/login',
      element: <LoginPage shouldRenderGeneralLoader={shouldRenderGeneralLoader} />,
      children: [
        { element: <Navigate to={Paths.LoginPhoneNumber} replace />, index: true },
        { path: Paths.LoginPhoneNumber, element: <VerifyPhoneNumber /> },
        { path: Paths.LoginCode, element: <VerifyCode /> },
        { path: Paths.LoginEmail, element: <VerifyEmail /> },
        { path: Paths.LoginInvitation, element: <Invitation /> },
        { path: Paths.PasswordReset, element: <SendResetPasswordEmail /> },
        { path: Paths.PasswordEdit, element: <EditPassword /> },
        { path: Paths.LoginFromToken, element: <FromToken /> },
        { path: Paths.LoginFromIdentityProvider, element: <FromIdentityProvider /> },
      ],
    },
    {
      path: '/onboarding',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessOnboardingPages(currentRole), <OnboardingLayout />)
      ),
      children: withSectionOnboardingStepCheck([
        { element: <Navigate to={Paths.OnboardingPharmacistOrLab} replace />, index: true },
        { path: Paths.OnboardingPharmacistOrLab, element: <PharmacistOrLabPage /> },
        { path: Paths.OnboardingLabUnavailable, element: <LabUnavailablePage /> },
        { path: Paths.OnboardingCreateProfile, element: <CreateProfilePage /> },
        { path: Paths.OnboardingSearchPharmacy, element: <SearchPharmacyPage /> },
        { path: Paths.OnboardingCreatePharmacy, element: <CreatePharmacyPage /> },
        { path: Paths.OnboardingJoinPharmacy, element: <JoinPharmacyPage /> },
        { path: Paths.OnboardingVerifyEmail, element: <VerifyEmailPage /> },
        { path: Paths.OnboardingVerifyAccount, element: <VerifyAccountPage /> },
      ]),
    },
    {
      path: '/onboarder',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessOnboarderPages(currentUser), <OnboarderLayout />)
      ),
      children: [
        { element: <Navigate to={Paths.OnboarderPharmacies} replace />, index: true },
        { path: Paths.OnboarderPharmacies, element: <OnboarderPharmaciesPage /> },
        { path: Paths.OnboarderPharmacyEdit, element: <OnboarderPharmacyEditPage /> },
      ],
    },
    {
      path: '/admin',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessAdminPages(currentUser), <MainLayout />)
      ),
      children: [
        { element: <Navigate to="/admin/home" replace />, index: true },
        { path: Paths.AdminHome, element: <AdminHomePage /> },
        { path: Paths.AdminPharmaciesMerge, element: <AdminPharmaciesMergePage /> },
        { path: Paths.AdminImpersonate, element: <AdminImpersonatePage /> },
        { path: Paths.AdminDatamatrix, element: <AdminDatamatrixGeneratorPage /> },
        {
          path: Paths.AdminSelloutPanelistGenerator,
          element: <AdminSelloutPanelistGeneratorPage />,
        },
        { path: Paths.AdminIngestionsHub, element: <AdminIngestionsHubPage /> },
        { path: Paths.AdminNewIngestion, element: <AdminIngestionHubNewPage /> },
        { path: Paths.AdminInboundMails, element: <AdminInboundMailsPage /> },
        { path: Paths.AdminOrders, element: <AdminOrdersPage /> },
        { path: Paths.AdminOrder, element: <AdminOrderPage /> },
        { path: Paths.AdminPharmacies, element: <AdminPharmaciesPage /> },
        { path: Paths.AdminPharmacyNew, element: <AdminPharmacyNewPage /> },
        { path: Paths.AdminPharmacy, element: <AdminPharmacyPage /> },
        { path: Paths.AdminOrderEdit, element: <AdminOrderEditPage /> },
        { path: Paths.AdminOrderNew, element: <AdminOrderNewPage /> },
        { path: Paths.AdminLabTrades, element: <AdminLabTradesPage /> },
        { path: Paths.AdminExpiredProductsRequests, element: <AdminExpiredProductsRequestsPage /> },
        { path: Paths.AdminExpiredProductsRequest, element: <AdminExpiredProductsRequestPage /> },
        { path: Paths.AdminClaims, element: <AdminClaimsPage /> },
        { path: Paths.AdminClaim, element: <AdminClaimPage /> },
        { path: Paths.AdminTrades, element: <AdminTradesPage /> },
        { path: Paths.AdminTrade, element: <AdminTradePage /> },
        { path: Paths.AdminTradesInstances, element: <AdminTradesInstancesPage /> },
        { path: Paths.AdminTradeInstance, element: <AdminTradeInstancePage /> },
        { path: Paths.AdminGroups, element: <AdminGroupsPage /> },
        { path: Paths.AdminGroupNew, element: <AdminGroupNewPage /> },
        { path: Paths.AdminGroup, element: <AdminGroupPage /> },
        { path: Paths.AdminLabs, element: <AdminLabsPage /> },
        { path: Paths.AdminLabNew, element: <AdminLabNewPage /> },
        { path: Paths.AdminLab, element: <AdminLabPage /> },
        { path: Paths.AdminMarketingTools, element: <AdminMarketingToolsPage /> },
        { path: Paths.AdminMarketingToolNew, element: <AdminMarketingToolNewPage /> },
        { path: Paths.AdminMarketingTool, element: <AdminMarketingToolPage /> },
        { path: Paths.AdminNewsfeedPosts, element: <AdminNewsfeedPostsPage /> },
        { path: Paths.AdminNewsfeedPost, element: <AdminNewsfeedPostPage /> },
        { path: Paths.AdminNewsfeedPostNew, element: <AdminNewsfeedPostNewPage /> },
        { path: Paths.AdminNewsfeedPostEdit, element: <AdminNewsfeedPostEditPage /> },
        { path: Paths.AdminClaimRelaunchPlans, element: <AdminClaimRelaunchPlansPage /> },
        { path: Paths.AdminClaimRelaunchPlanNew, element: <AdminClaimRelaunchPlanNewPage /> },
        { path: Paths.AdminClaimRelaunchPlanEdit, element: <AdminClaimRelaunchPlanEditPage /> },
        { path: Paths.AdminUsers, element: <AdminUsersPage /> },
        { path: Paths.AdminUser, element: <AdminUserPage /> },
        { path: Paths.AdminUserNew, element: <AdminUserNewPage /> },
        { path: Paths.AdminUserEdit, element: <AdminUserEditPage /> },
        { path: Paths.AdminGroupsLabs, element: <AdminGroupsLabsPage /> },
        { path: Paths.AdminCheckNeedingPharmacies, element: <AdminCheckNeedingPharmaciesPage /> },
        { path: Paths.AdminReferentialPharmacies, element: <AdminReferentialPharmaciesPage /> },
      ],
    },
    {
      path: '/pharmacy',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessPharmacyPages(currentRole), <MainLayout />)
      ),
      children: [
        {
          element: <Navigate to={Paths.PharmacyTrades} replace />,
          index: true,
        },
        { path: Paths.PharmacyClaims, element: <PharmacyClaims /> },
        { path: Paths.PharmacyClaimNewPage, element: <PharmacyClaimNewPage /> },
        { path: Paths.PharmacyClaim, element: <PharmacyClaim /> },
        { path: Paths.PharmacyTrades, element: <PharmacyTradesPage /> },
        { path: Paths.PharmacyTrade, element: <PharmacyTradePage /> },
        { path: Paths.PharmacyTradeNew, element: <PharmacyTradeNewPage /> },
        { path: Paths.PharmacyStripe, element: <PharmacyStripePage /> },
        { path: Paths.PharmacyStripeBillingForm, element: <PharmacyStripeBillingFormPage /> },
        { path: Paths.PharmacyLgoSetting, element: <PharmacyLgoSettingPage /> },
        {
          path: Paths.PharmacyLabsDiscussions,
          element: pharmacy?.is_lab_chat_enabled ? (
            <PharmacyLabsDiscussionsPage />
          ) : (
            <Navigate to={Paths.NotFound} />
          ),
          children: [{ path: ':id', element: <PharmacyLabsDiscussionsPage /> }],
        },
        {
          path: Paths.PharmacyOrders,
          element: pharmacy?.is_order_enabled ? <OrdersPage /> : <Navigate to={Paths.NotFound} />,
          children: [
            { path: ':id', element: <OrdersPage /> },
            { path: 'new', element: <OrdersPage /> },
            { path: 'restocking', element: <OrdersPage /> },
          ],
        },
        { path: Paths.PharmacyLabsPharmacies, element: <PharmacyLabsPage /> },
        { path: Paths.PharmacyLabsPharmacy, element: <PharmacyLabsPharmacyPage /> },
        { path: Paths.PharmacyExpiredProductsRequest, element: <PharmacyExpiredProductsRequest /> },
        { path: Paths.PharmacyPharmacyEdit, element: <PharmacyPharmacyEditPage /> },
        { path: Paths.PharmacyCompensations, element: <PharmacyCompensationsPage /> },
      ],
    },
    {
      path: '/user',
      element: waitForContextLoadingAnd(displayRouteOnlyIf(!isNil(currentRole), <MainLayout />)),
      children: [
        { element: <Navigate to={Paths.EditCurrentUser} replace />, index: true },
        { path: Paths.EditCurrentUser, element: <ProfilePage /> },
        {
          path: Paths.PharmacyEditCurrentPharmacy,
          element: displayRouteOnlyIf(
            !!currentRole && isPharmacyRole(currentRole),
            <PharmacyPage />
          ),
        },
      ],
    },
    {
      path: '/group',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessGroupPages(currentRole), <MainLayout />)
      ),
      children: [
        { element: <Navigate to={Paths.GroupDashboard} replace />, index: true },
        { path: Paths.GroupDashboard, element: <GroupDashboardPage /> },
        { path: Paths.GroupNewsfeedPosts, element: <GroupNewsfeedPostsPage /> },
        { path: Paths.GroupNewsfeedPost, element: <GroupNewsfeedPostPage /> },
        { path: Paths.GroupNewsfeedPostEdit, element: <GroupNewsfeedPostEditPage /> },
        { path: Paths.GroupNewsfeedPostNew, element: <GroupNewsfeedPostNewPage /> },
        { path: Paths.GroupGroupsPharmacies, element: <GroupGroupsPharmaciesPage /> },
        { path: Paths.GroupGroupsPharmaciesEdit, element: <GroupGroupsPharmaciesEditPage /> },
        { path: Paths.GroupPharmacyLists, element: <SharedPharmacyListsPage /> },
        { path: Paths.GroupPharmacyListNew, element: <SharedPharmacyListIngestionPage /> },
        { path: Paths.GroupPharmacyList, element: <SharedPharmacyListPage /> },
        { path: Paths.GroupClaims, element: <GroupClaimsPage /> },
        { path: Paths.GroupClaim, element: <GroupClaimPage /> },
        { path: Paths.GroupGroupsLabs, element: <GroupGroupsLabsPage /> },
        { path: Paths.GroupGroupsLabsEdit, element: <GroupGroupsLabsEditPage /> },
        { path: Paths.GroupTrades, element: <GroupTradesPage /> },
        { path: Paths.GroupTrade, element: <GroupTradePage /> },
        { path: Paths.GroupTradeNew, element: <GroupTradeNewPage /> },
        { path: Paths.GroupTradeEdit, element: <GroupTradeEditPage /> },
        { path: Paths.GroupGroupEdit, element: <GroupEditPage /> },
      ],
    },
    {
      path: '/',
      element: waitForContextLoadingAnd(<MainLayout />),
      children: [
        { path: Paths.LabSecuredClaimOld, element: <OldSecuredLabClaimPage /> },
        { path: Paths.SecuredLabClaim, element: <SecuredLabClaimPage /> },
      ],
    },
    {
      path: '/lab',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessLabPages(currentRole), <MainLayout />)
      ),
      children: [
        { element: <Navigate to={Paths.LabTradesDashboard} replace />, index: true },
        { path: Paths.LabTradesDashboard, element: <LabTradesDashboardPage /> },
        { path: Paths.LabTrades, element: <LabTradeInstancesPage /> },
        { path: Paths.LabMasterTrades, element: <LabMasterTradesPage /> },
        { path: Paths.LabMasterTradeNew, element: <LabMasterTradeNewPage /> },
        { path: Paths.LabMasterTrade, element: <LabMasterTradePage /> },
        { path: Paths.LabClaimsList, element: <LabClaimsPage /> },
        { path: Paths.LabClaim, element: <LabClaimPage /> },
        { path: Paths.LabGroupsLabs, element: <LabGroupsLabsPage /> },
        { path: Paths.LabLabsPharmacies, element: <LabLabsPharmaciesPage /> },
        { path: Paths.LabLabsPharmacy, element: <LabsPharmacyPage /> },
        { path: Paths.LabGuideItems, element: <LabGuideItemsPage /> },
        { path: Paths.LabGuideItemNew, element: <LabGuideItemNewPage /> },
        { path: Paths.LabGuideItemEdit, element: <LabGuideItemEditPage /> },
        { path: Paths.LabRegionNew, element: <LabRegionNewPage /> },
        { path: Paths.LabRegionEdit, element: <LabRegionEditPage /> },
        { path: Paths.LabGroupsLabEdit, element: <LabGroupsLabEditPage /> },
        { path: Paths.LabExpiredProductsRequestsList, element: <LabExpiredProductsRequestsPage /> },
        { path: Paths.LabClaimsDashboard, element: <LabClaimsDashboardPage /> },
        { path: Paths.LabEditLab, element: <LabEditPage /> },
        {
          path: Paths.LabExpiredProductsRequestsDashboard,
          element: <LabExpiredProductsRequestsDashboardPage />,
        },
        { path: Paths.LabExpiredProductsRequest, element: <LabExpiredProductsRequestPage /> },
        { path: Paths.LabTopicNew, element: <LabTopicNewPage /> },
        { path: Paths.LabTopicEdit, element: <LabTopicEditPage /> },
        { path: Paths.LabPharmacyLists, element: <SharedPharmacyListsPage /> },
        { path: Paths.LabPharmacyListNew, element: <SharedPharmacyListIngestionPage /> },
        { path: Paths.LabPharmacyList, element: <SharedPharmacyListPage /> },
        { path: Paths.LabOrdersList, element: <LabOrdersListPage /> },
        { path: Paths.LabOrder, element: <LabOrderPage /> },
        { path: Paths.LabProductsList, element: <LabProductsListPage /> },
      ],
    },
    {
      // Only with lab Token
      path: '/securedaccess',
      element: waitForContextLoadingAnd(
        authToken === null && labToken !== null ? <MainLayout /> : <Navigate to={Paths.LabTrades} />
      ),
      children: [
        { element: <Navigate to={Paths.LabSecuredAccessTrades} replace />, index: true },
        { path: Paths.LabSecuredAccessDashboard, element: <LabTradesDashboardPage /> },
        { path: Paths.LabSecuredAccessTrades, element: <LabTradeInstancesPage /> },
      ],
    },
    {
      path: '/',
      element: waitForContextLoadingAnd(displayRouteOnlyIf(!isNil(currentRole), <MainLayout />)),
      children: [
        { path: Paths.Unauthorized, element: <UnauthorizedPage /> },
        { path: Paths.NotFound, element: <NotFound /> },
      ],
    },
    {
      path: '*',
      element: waitForContextLoadingAnd(<LogoOnlyLayout />),
      children: [{ path: '*', element: <Navigate to={Paths.NotFound} replace /> }],
    },
    { path: Paths.InvalidToken, element: waitForContextLoadingAnd(<InvalidToken />) },
    { path: Paths.StripeState, element: waitForContextLoadingAnd(<StripeStatePage />) },
    { path: '*', element: waitForContextLoadingAnd(<Navigate to={Paths.NotFound} replace />) },
  ]);
}
const LoginPage = Loadable(lazyRetry(() => import('pages/shared/Login/LoginPage')));
const LabTradesDashboardPage = Loadable(
  lazyRetry(() => import('pages/lab/Trades/Dashboard/LabTradesDashboardPage'))
);
const LabTradeInstancesPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabTradeInstances/LabTradeInstancesPage/LabTradeInstancesPage')
  )
);
const LabMasterTradesPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabMasterTrades/LabMasterTradesPage/LabMasterTradesPage')
  )
);
const LabMasterTradeNewPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabMasterTrades/LabMasterTradeNewPage/MasterTradeNewPage')
  )
);
const LabMasterTradePage = Loadable(
  lazyRetry(() => import('pages/lab/Trades/LabMasterTrades/LabMasterTradePage/LabMasterTradePage'))
);
const LabGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/lab/GroupsLabs/LabGroupsLabsPage/LabGroupsLabsPage'))
);
const LabClaimsPage = Loadable(
  lazyRetry(() => import('pages/lab/Claims/LabClaimsPage/LabClaimsPage'))
);
const LabClaimPage = Loadable(lazyRetry(() => import('pages/lab/Claims/LabClaimPage')));
const SecuredLabClaimPage = Loadable(
  lazyRetry(() => import('pages/secured/Claims/SecuredLabClaimPage'))
);
const OldSecuredLabClaimPage = Loadable(
  lazyRetry(() => import('pages/secured/Claims/OldSecuredLabClaimPage'))
);
const LabLabsPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/lab/LabsPharmacies/LabLabsPharmaciesPage/LabLabsPharmaciesPage'))
);
const LabsPharmacyPage = Loadable(
  lazyRetry(() => import('pages/lab/LabsPharmacies/LabLabsPharmacyPage/LabLabsPharmacyPage'))
);
const LabGuideItemsPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemsPage/LabGuideItemsPage'))
);
const LabGuideItemNewPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemNewPage'))
);
const LabGuideItemEditPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemEditPage'))
);
const LabRegionNewPage = Loadable(lazyRetry(() => import('pages/lab/Regions/LabRegionNewPage')));
const LabRegionEditPage = Loadable(lazyRetry(() => import('pages/lab/Regions/LabRegionEditPage')));
const LabGroupsLabEditPage = Loadable(
  lazyRetry(() => import('pages/lab/GroupsLabs/LabGroupsLabEditPage'))
);
const LabExpiredProductsRequestsPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestsPage/LabExpiredProductsRequestsPage'
      )
  )
);
const LabClaimsDashboardPage = Loadable(
  lazyRetry(() => import('pages/lab/Claims/LabClaimsDashboardPage/LabClaimsDashboardPage'))
);
const LabEditPage = Loadable(lazyRetry(() => import('pages/lab/Edit/LabEditPage')));
const LabExpiredProductsRequestsDashboardPage = Loadable(
  lazyRetry(
    () => import('pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestsDashboardPage')
  )
);
const LabExpiredProductsRequestPage = Loadable(
  lazyRetry(() => import('pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestPage'))
);
const LabTopicNewPage = Loadable(lazyRetry(() => import('pages/lab/Topics/LabTopicNewPage')));
const LabTopicEditPage = Loadable(lazyRetry(() => import('pages/lab/Topics/LabTopicEditPage')));
const LabOrdersListPage = Loadable(
  lazyRetry(() => import('pages/lab/Orders/LabOrdersPage/LabOrdersPage'))
);
const LabOrderPage = Loadable(
  lazyRetry(() => import('pages/lab/Orders/LabOrderPage/LabOrderPage'))
);
const LabProductsListPage = Loadable(lazyRetry(() => import('pages/lab/Orders/Products')));

const PharmacyTradesPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradesPage/PharmacyTradesPage'))
);
const PharmacyTradePage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradePage/PharmacyTradePage'))
);
const PharmacyTradeNewPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradeNewPage/PharmacyTradeNewPage'))
);
const PharmacyLabsDiscussionsPage = Loadable(
  lazyRetry(
    () =>
      import('pages/pharmacy/SalesRepDiscussions/SalesRepDiscussionsPage/SalesRepDiscussionsPage')
  )
);

const OrdersPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Orders/PharmacyOrdersPage/PharmacyOrdersPage'))
);
const ProfilePage = Loadable(lazyRetry(() => import('pages/shared/Profile/ProfilePage')));
const PharmacyPage = Loadable(lazyRetry(() => import('pages/shared/Profile/PharmacyPage')));
const PharmacyCompensationsPage = Loadable(
  lazyRetry(
    () => import('pages/pharmacy/Compensations/PharmacyCompensationsPage/PharmacyCompensationsPage')
  )
);

const GroupDashboardPage = Loadable(
  lazyRetry(() => import('pages/group/Dashboard/GroupDashboardPage'))
);
const GroupNewsfeedPostsPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostsPage/GroupNewsfeedPostsPage'))
);
const GroupNewsfeedPostPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostPage/GroupNewsfeedPostPage'))
);
const GroupNewsfeedPostEditPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostEditPage'))
);
const GroupNewsfeedPostNewPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostNewPage'))
);

const GroupGroupsPharmaciesPage = Loadable(
  lazyRetry(
    () => import('pages/group/GroupsPharmacies/GroupGroupsPharmaciesPage/GroupGroupsPharmaciesPage')
  )
);
const GroupGroupsPharmaciesEditPage = Loadable(
  lazyRetry(() => import('pages/group/GroupsPharmacies/GroupGroupsPharmaciesEditPage'))
);
const GroupTradesPage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradesPage/GroupTradesPage'))
);
const GroupTradePage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradePage/GroupTradePage'))
);
const GroupClaimsPage = Loadable(
  lazyRetry(() => import('pages/group/Claims/GroupClaimsPage/GroupClaimsPage'))
);
const GroupTradeNewPage = Loadable(lazyRetry(() => import('pages/group/Trades/GroupTradeNewPage')));
const GroupTradeEditPage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradeEditPage'))
);
const GroupClaimPage = Loadable(
  lazyRetry(() => import('pages/group/Claims/GroupClaimPage/GroupClaimPage'))
);
const GroupGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/group/GroupsLabs/GroupGroupsLabsPage/GroupGroupsLabsPage'))
);
const GroupGroupsLabsEditPage = Loadable(
  lazyRetry(() => import('pages/group/GroupsLabs/GroupGroupsLabsEditPage'))
);
const GroupEditPage = Loadable(lazyRetry(() => import('pages/group/Edit/GroupEditPage')));
const PharmacyClaims = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/PharmacyCasesPage/PharmacyCasesPage'))
);
const PharmacyClaimNewPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/Claims/PharmacyClaimNewPage/PharmacyClaimNewPage'))
);
const PharmacyClaim = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/Claims/PharmacyClaimPage/PharmacyClaimPage'))
);
const PharmacyExpiredProductsRequest = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/pharmacy/Cases/ExpiredProductsRequests/PharmacyExpiredProductsRequestPage/PharmacyExpiredProductsRequestPage'
      )
  )
);
const PharmacyLabsPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Labs/PharmacyLabsPage/PharmacyLabsPage'))
);
const PharmacyLabsPharmacyPage = Loadable(
  lazyRetry(
    () => import('pages/pharmacy/LabsPharmacies/PharmacyLabsPharmacyPage/PharmacyLabsPharmacyPage')
  )
);
const PharmacyPharmacyEditPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Edit/PharmacyEditPage'))
);
const SharedPharmacyListPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListPage'))
);
const SharedPharmacyListIngestionPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListIngestionPage'))
);
const SharedPharmacyListsPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListsPage'))
);
const UnauthorizedPage = Loadable(lazyRetry(() => import('pages/shared/errors/Page403')));
const NotFound = Loadable(lazyRetry(() => import('pages/shared/errors/Page404')));
const InvalidToken = Loadable(lazyRetry(() => import('pages/shared/errors/Page498')));
const PharmacistOrLabPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/PharmacistOrLabPage'))
);
const LabUnavailablePage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/LabUnavailablePage'))
);
const CreateProfilePage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/CreateProfilePage'))
);
const SearchPharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/SearchPharmacyPage'))
);
const CreatePharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/CreatePharmacyPage'))
);
const JoinPharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/JoinPharmacyPage'))
);
const VerifyEmailPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/VerifyEmailPage'))
);
const VerifyAccountPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/VerifyAccountPage'))
);
const OnboarderPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/onboarder/OnboarderPharmaciesPage/OnboarderPharmaciesPage'))
);
const OnboarderPharmacyEditPage = Loadable(
  lazyRetry(() => import('pages/onboarder/OnboarderPharmacyEditPage/OnboarderPharmacyEditPage'))
);
const AdminHomePage = Loadable(lazyRetry(() => import('pages/admin/Home/AdminHomePage')));
const AdminImpersonatePage = Loadable(
  lazyRetry(() => import('pages/admin/Impersonate/AdminImpersonatePage'))
);
const AdminInboundMailsPage = Loadable(
  lazyRetry(() => import('pages/admin/InboundMails/AdminInboundMailsPage/AdminInboundMailsPage'))
);
const AdminOrdersPage = Loadable(
  lazyRetry(() => import('pages/admin/Orders/AdminOrdersPage/AdminOrdersPage'))
);
const AdminOrderPage = Loadable(lazyRetry(() => import('pages/admin/Orders/AdminOrderPage')));
const AdminOrderEditPage = Loadable(
  lazyRetry(() => import('pages/admin/Orders/AdminOrderEditPage'))
);
const AdminOrderNewPage = Loadable(lazyRetry(() => import('pages/admin/Orders/AdminOrderNewPage')));
const AdminLabTradesPage = Loadable(
  lazyRetry(() => import('pages/admin/LabTrades/AdminLabTradesPage/AdminLabTradesPage'))
);
const AdminExpiredProductsRequestsPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ExpiredProductsRequests/AdminExpiredProductsRequestsPage/AdminExpiredProductsRequestsPage'
      )
  )
);
const AdminExpiredProductsRequestPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ExpiredProductsRequests/AdminExpiredProductsRequestPage/AdminExpiredProductsRequestPage'
      )
  )
);
const AdminClaimsPage = Loadable(
  lazyRetry(() => import('pages/admin/Claims/AdminClaimsPage/AdminClaimsPage'))
);
const AdminClaimPage = Loadable(
  lazyRetry(() => import('pages/admin/Claims/AdminClaimPage/AdminClaimPage'))
);
const AdminPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmaciesPage/AdminPharmaciesPage'))
);
const AdminPharmacyNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmacyNewPage'))
);
const AdminPharmacyPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmacyPage/AdminPharmacyPage'))
);
const AdminGroupsPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupsPage/AdminGroupsPage'))
);
const AdminGroupNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupNewPage/AdminGroupNewPage'))
);
const AdminGroupPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupPage/AdminGroupPage'))
);
const AdminLabsPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabsPage/AdminLabsPage'))
);
const AdminLabNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabNewPage/AdminLabNewPage'))
);
const AdminLabPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabPage/AdminLabPage'))
);
const AdminTradesPage = Loadable(
  lazyRetry(() => import('pages/admin/Trades/AdminTradesPage/AdminTradesPage'))
);
const AdminTradePage = Loadable(
  lazyRetry(() => import('pages/admin/Trades/AdminTradePage/AdminTradePage'))
);
const AdminTradesInstancesPage = Loadable(
  lazyRetry(
    () => import('pages/admin/TradesInstances/AdminTradesInstancesPage/AdminTradesInstancesPage')
  )
);
const AdminTradeInstancePage = Loadable(
  lazyRetry(
    () => import('pages/admin/TradesInstances/AdminTradeInstancePage/AdminTradeInstancePage')
  )
);
const AdminPharmaciesMergePage = Loadable(
  lazyRetry(() => import('pages/admin/PharmaciesMerge/AdminPharmaciesMergePage'))
);
const AdminDatamatrixGeneratorPage = Loadable(
  lazyRetry(() => import('pages/admin/DatamatrixGenerator/AdminDatamatrixGeneratorPage'))
);
const AdminIngestionsHubPage = Loadable(
  lazyRetry(() => import('pages/admin/IngestionsHub/AdminIngestionsHubPage/AdminIngestionsHubPage'))
);
const AdminIngestionHubNewPage = Loadable(
  lazyRetry(
    () => import('pages/admin/IngestionsHub/AdminIngestionsHubNewPage/AdminIngestionHubNewPage')
  )
);
const AdminMarketingToolsPage = Loadable(
  lazyRetry(
    () => import('pages/admin/MarketingTools/AdminMarketingToolsPage/AdminMarketingToolsPage')
  )
);
const AdminMarketingToolNewPage = Loadable(
  lazyRetry(() => import('pages/admin/MarketingTools/AdminMarketingToolNewPage'))
);
const AdminMarketingToolPage = Loadable(
  lazyRetry(
    () => import('pages/admin/MarketingTools/AdminMarketingToolPage/AdminMarketingToolPage')
  )
);
const AdminUsersPage = Loadable(
  lazyRetry(() => import('pages/admin/Users/AdminUsersPage/AdminUsersPage'))
);
const AdminUserPage = Loadable(
  lazyRetry(() => import('pages/admin/Users/AdminUserPage/AdminUserPage'))
);
const AdminUserNewPage = Loadable(lazyRetry(() => import('pages/admin/Users/AdminUserNewPage')));
const AdminUserEditPage = Loadable(lazyRetry(() => import('pages/admin/Users/AdminUserEditPage')));
const AdminNewsfeedPostsPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostsPage/AdminNewsfeedPostsPage'))
);
const AdminNewsfeedPostPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostPage/AdminNewsfeedPostPage'))
);
const AdminNewsfeedPostNewPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostNewPage'))
);
const AdminNewsfeedPostEditPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostEditPage'))
);
const AdminClaimRelaunchPlansPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlansPage/AdminClaimRelaunchPlansPage'
      )
  )
);
const AdminClaimRelaunchPlanNewPage = Loadable(
  lazyRetry(() => import('pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlanNewPage'))
);
const AdminClaimRelaunchPlanEditPage = Loadable(
  lazyRetry(() => import('pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlanEditPage'))
);
const AdminGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/admin/GroupsLabs/AdminGroupsLabsPage/AdminGroupsLabsPage'))
);
const AdminCheckNeedingPharmaciesPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/CheckNeedingPharmacies/AdminCheckNeedingPharmaciesPage/AdminCheckNeedingPharmaciesPage'
      )
  )
);
const AdminReferentialPharmaciesPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ReferentialPharmacies/AdminReferentialPharmaciesPage/AdminReferentialPharmaciesPage'
      )
  )
);
const SendResetPasswordEmail = Loadable(
  lazyRetry(() => import('pages/shared/ResetPassword/SendEmail/SendEmail'))
);
const EditPassword = Loadable(
  lazyRetry(() => import('pages/shared/ResetPassword/EditPassword/EditPassword'))
);
const PharmacyStripePage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Stripe/PharmacyStripePage'))
);
const StripeStatePage = Loadable(lazyRetry(() => import('pages/shared/StripeStatePage')));
const PharmacyStripeBillingFormPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Stripe/PharmacyStripeBillingFormPage'))
);
const PharmacyLgoSettingPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/LgoSetting/PharmacyLgoSettingPage'))
);
