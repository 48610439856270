import moment from 'moment';
import { getLastDayOfMonth } from 'utils/dateUtils';

type DateWithoutTimeType = {
  day: number;
  month: number;
  year: number;
};

export class DateWithoutTime {
  private date: DateWithoutTimeType;

  private constructor(year: number, month: number, day: number) {
    this.date = {
      day: day,
      month: month,
      year: year,
    };

    if (isNaN(this.date.day) || isNaN(this.date.month) || isNaN(this.date.year)) {
      throw new Error('Invalid Date, should have YYYY-MM-DD format');
    }
  }

  // METHODS

  toString = (format = 'YYYY-MM-DD'): string => moment(this.date).format(format);

  toDate = (): Date => new Date(this.date.year, this.date.month, this.date.day);

  toLastDayOfMonth = (): DateWithoutTime => {
    const lastDay = getLastDayOfMonth(this.toDate());
    return new DateWithoutTime(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate());
  };

  isToday = (): boolean => this.toString() === DateWithoutTime.today().toString();

  isFuture = (): boolean => this.toString() > DateWithoutTime.today().toString();

  isFutureOrToday = (): boolean => this.isFuture() || this.isToday();

  isPast = (): boolean => this.toString() < DateWithoutTime.today().toString();

  isPastOrToday = (): boolean => this.isPast() || this.isToday();

  isBefore = (date: DateWithoutTime) => moment(this.date).isBefore(moment(date.date));

  addMonths = (monthsToAdd: number): DateWithoutTime => {
    const date = moment(this.toDate());
    const newDate = date.add(monthsToAdd, 'months').toDate();

    return new DateWithoutTime(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  };

  addDays = (daysToAdd: number): DateWithoutTime => {
    const date = moment(this.toDate());
    const newDate = date.add(daysToAdd, 'days').toDate();

    return new DateWithoutTime(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  };

  getMonth = () => this.date.month;

  getYear = () => this.date.year;

  getDaysDiff = (date: DateWithoutTime) => moment(date.date).diff(moment(this.date), 'days', true);

  // STATIC FUNCTIONS

  static today = (): DateWithoutTime => {
    const date = new Date();

    return new DateWithoutTime(date.getFullYear(), date.getMonth(), date.getDate());
  };

  static lastDayOfCurrentMonth = (): DateWithoutTime => {
    const date = getLastDayOfMonth(new Date());

    return new DateWithoutTime(date.getFullYear(), date.getMonth(), date.getDate());
  };
  static fromDate = (date: Date): DateWithoutTime =>
    new DateWithoutTime(date.getFullYear(), date.getMonth(), date.getDate());

  static fromString = (date: string): DateWithoutTime => {
    const splittedDate = date.split('-');

    return new DateWithoutTime(+splittedDate[0], +splittedDate[1] - 1, +splittedDate[2]);
  };

  static fromDateTimeString = (date: string): DateWithoutTime => {
    return this.fromString(moment(date).format('YYYY-MM-DD'));
  };

  static fromStringOrNull = (date: string | null): DateWithoutTime | null =>
    date ? this.fromString(date) : null;

  static fromStringOrUndefined = (date: string | null): DateWithoutTime | undefined =>
    date ? this.fromString(date) : undefined;

  static fromStringOrToday = (date?: string): DateWithoutTime =>
    date ? this.fromString(date) : this.today();
}
