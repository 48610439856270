import { Button, Typography } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { LoginLayout } from '../LoginLayout';
import { PharmacistButton } from '../PharmacistButton';
import { SubtitleText } from '../styles';
import { EmailForm } from './EmailForm';
import { PasswordForm } from './PasswordForm';

export type Step = 'email' | 'password';

export const VerifyEmail: FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>();
  const [step, setStep] = useState<Step>('email');

  return (
    <LoginLayout
      title={t('emailLogin.welcomeOnFaks')}
      subtitle={
        <>
          <Typography>{t('emailLogin.useLoginDetails')}</Typography>
          <Typography color="primary.main" pt={2}>
            {`${t('verifyEmail.yourePharmacist')} `}
            <Link to={Paths.LoginPhoneNumber}>{t('shared.pharmacistSpace')}</Link>
          </Typography>
        </>
      }
    >
      {step === 'email' && <EmailForm setEmail={setEmail} setStep={setStep} />}
      {step === 'password' && email && <PasswordForm submittedEmail={email} setStep={setStep} />}
      <SubtitleText mb={1} mt={7}>
        {t('emailLogin.noAccount')}
      </SubtitleText>
      <Button variant="outlined" size="large" fullWidth href={Paths.LoginInvitation}>
        {t('invitation.askForInvitation')}
      </Button>
      <PharmacistButton />
    </LoginLayout>
  );
};

export default VerifyEmail;
