import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { useTranslation } from 'locales/i18n';
import { Paths } from 'routes/paths';

export function FaksAdminNavSection() {
  const { t } = useTranslation();

  const navItems: NavListProps[] = [
    {
      title: t('shared.home'),
      path: Paths.AdminHome,
      icon: <NavIcon name="ic_home" />,
      hasDivider: true,
    },
    {
      title: t('shared.invoices'),
      path: `${Paths.AdminClaims}?status=not_seen`,
      icon: <NavIcon name="ic_invoice" />,
    },
    {
      title: t('shared.expired'),
      path: `${Paths.AdminExpiredProductsRequests}?status=ongoing`,
      icon: <NavIcon name="ic_expired" />,
    },
    {
      title: t('shared.orders'),
      path: `${Paths.AdminOrders}?status=transmitted`,
      icon: <NavIcon name="ic_cart" />,
    },
    { title: t('navbar.trades'), path: Paths.AdminTrades, icon: <NavIcon name="ic_ecommerce" /> },
    {
      title: t('navbar.labTrades'),
      path: Paths.AdminLabTrades,
      icon: <NavIcon name="ic_ecommerce" />,
    },
    {
      title: t('shared.participations'),
      path: Paths.AdminTradesInstances,
      icon: <NavIcon name="ic_ecommerce" />,
    },
    {
      title: t('navbar.newsfeedPosts'),
      path: Paths.AdminNewsfeedPosts,
      icon: <NavIcon name="ic_news" />,
    },
    {
      title: t('shared.inboundMails'),
      path: Paths.AdminInboundMails,
      icon: <NavIcon name="ic_mail" />,
      hasDivider: true,
    },
    {
      title: t('shared.pharmacies'),
      path: Paths.AdminPharmacies,
      icon: <NavIcon name="ic_pharma" />,
    },
    { title: t('shared.groups'), path: Paths.AdminGroups, icon: <NavIcon name="ic_group" /> },
    { title: t('shared.labs'), path: Paths.AdminLabs, icon: <NavIcon name="ic_lab" /> },
    {
      title: t('shared.users'),
      path: Paths.AdminUsers,
      icon: <NavIcon name="ic_user" />,
      hasDivider: true,
    },
    {
      title: t('navbar.tools'),
      path: '/admin/tools',
      icon: <NavIcon name="ic_tools" />,
      children: [
        {
          title: t('shared.impersonate'),
          path: Paths.AdminImpersonate,
        },
        {
          title: t('navbar.marketingTools'),
          path: Paths.AdminMarketingTools,
        },
        {
          title: t('shared.ingestionsHub'),
          path: Paths.AdminIngestionsHub,
        },
        {
          title: t('shared.datamatrix'),
          path: Paths.AdminDatamatrix,
        },
        ...(process.env.REACT_APP_ENV === 'production'
          ? []
          : [
              {
                title: t('shared.selloutPanelistGenerator'),
                path: Paths.AdminSelloutPanelistGenerator,
              },
            ]),
        {
          title: t('navbar.pharmaciesMerge'),
          path: Paths.AdminPharmaciesMerge,
        },
        {
          title: t('navbar.groupsLabs'),
          path: Paths.AdminGroupsLabs,
        },
        {
          title: t('navbar.checkNeedingPharmacies'),
          path: Paths.AdminCheckNeedingPharmacies,
        },
        {
          title: t('navbar.referentialPharmacies'),
          path: Paths.AdminReferentialPharmacies,
        },
      ],
    },
  ];

  return <NavSection navItems={navItems} />;
}
