import { useTheme } from '@mui/material';
import { useIndexQuery, useShowQuery } from 'api';
import RoundedExclamation from 'components/icons/RoundedExclamation';
import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { NotifBadgeIcon } from 'components/notifBadgeIcon/NotifBadgeIcon';
import { useCurrentLabOrThrow, useCurrentRoleOrThrow } from 'hooks/slices';
import { useGetResellerWordingType } from 'hooks/useGetResellerWordingType';
import { useScopedResellerTranslation, useTranslation } from 'locales/i18n';
import { generatePath } from 'react-router';
import { Paths } from 'routes/paths';

export function LabNavSection() {
  const { t } = useTranslation();
  const theme = useTheme();

  const currentRole = useCurrentRoleOrThrow();
  const isResourceAdmin = currentRole.kind === 'lab_admin';
  const { id: labId, is_client: isClient } = useCurrentLabOrThrow();
  const { resellerWording } = useGetResellerWordingType();
  const { tScoped } = useScopedResellerTranslation(resellerWording);

  const { data: labData } = useIndexQuery('labs/lab/statistics', undefined, {
    enabled: currentRole.kind !== 'lab_sales_rep_no_access',
  });
  const awaitingLabtradeInstancesCount = labData?.statistics.awaiting_lab_trades_instances_count;
  const labNotSeenInvoicesCount = labData?.statistics.not_seen_invoices_count;
  const pendingExpiredProductsRequestsCount =
    labData?.statistics.pending_expired_products_requests_count;

  const { data: currentLab, isLoading } = useShowQuery('labs', labId);
  const isTradePremium = currentLab?.lab.trades_lab_setting?.plan === 'premium';
  const isTradeNoAccess = currentLab?.lab.trades_lab_setting?.plan === 'none';
  const shouldSetupStripe =
    currentLab?.lab.stripe_lab_settings?.is_feature_enabled &&
    currentLab.lab.stripe_lab_settings.payment_method === 'sepa_debit' &&
    currentLab?.lab.stripe_lab_settings?.status !== 'ok';
  const expiredLabSetting = currentLab?.lab.expired_lab_setting;

  const navItems: NavListProps[] = [
    {
      title: t('navbar.trades'),
      path: '/lab/trades',
      icon: <NavIcon name="ic_booking" />,
      ...(!!awaitingLabtradeInstancesCount &&
        !isTradeNoAccess && {
          rightIcon: <NotifBadgeIcon count={awaitingLabtradeInstancesCount} />,
        }),
      ...(!isTradeNoAccess && {
        children: [
          {
            title: t('navbar.dashboard'),
            path: Paths.LabTradesDashboard,
            rightIcon: !isTradePremium && !isLoading ? <NavIcon name="ic_lock" /> : undefined,
          },
          {
            title: t('navbar.trades'),
            path: Paths.LabMasterTrades,
            rightIcon: !isTradePremium && !isLoading ? <NavIcon name="ic_lock" /> : undefined,
          },
          {
            title: t('navbar.labTradeInstances'),
            path: `${Paths.LabTrades}?status=awaiting_validation_by_lab`,
            ...(!!awaitingLabtradeInstancesCount && {
              rightIcon: <NotifBadgeIcon count={awaitingLabtradeInstancesCount} />,
            }),
          },
        ],
      }),
    },
    {
      title: t('shared.invoices'),
      path: '/lab/claims',
      icon: <NavIcon name="ic_invoice" />,
      ...(!!labNotSeenInvoicesCount && {
        rightIcon: <NotifBadgeIcon count={labNotSeenInvoicesCount} />,
      }),
      children: [
        {
          title: t('shared.dashboard'),
          path: Paths.LabClaimsDashboard,

          rightIcon: !isClient ? <NavIcon name="ic_lock" /> : undefined,
        },
        {
          title: t('shared.followUp'),
          path: `${Paths.LabClaimsList}?tab=pending`,
          ...(!!labNotSeenInvoicesCount && {
            rightIcon: <NotifBadgeIcon count={labNotSeenInvoicesCount} />,
          }),
        },
      ],
    },
    ...(expiredLabSetting?.is_feature_enabled
      ? [
          {
            title: t('shared.expired'),
            path: '/lab/expired_products_requests',
            icon: <NavIcon name="ic_expired" />,
            ...(!!pendingExpiredProductsRequestsCount && {
              rightIcon: <NotifBadgeIcon count={pendingExpiredProductsRequestsCount} />,
            }),
            children: [
              {
                title: t('navbar.dashboard'),
                path: Paths.LabExpiredProductsRequestsDashboard,
              },
              {
                title: t('shared.followUp'),
                path: `${Paths.LabExpiredProductsRequestsList}?status=signed_by_pharmacy`,
                ...(!!pendingExpiredProductsRequestsCount && {
                  rightIcon: <NotifBadgeIcon count={pendingExpiredProductsRequestsCount} />,
                }),
              },
            ],
          },
        ]
      : []),
    {
      title: tScoped('resellers'),
      path: '/lab/pharmacies',
      icon: <NavIcon name="ic_pharma" />,
      children: [
        {
          title: tScoped('resellers'),
          path: Paths.LabLabsPharmacies,
        },
        ...(isResourceAdmin
          ? [
              {
                title: tScoped('resellersList'),
                path: Paths.LabPharmacyLists,
              },
            ]
          : []),
      ],
    },
    ...(currentLab?.lab.orders_lab_setting.orders_management_enabled
      ? [
          {
            title: t('shared.orders'),
            path: Paths.LabOrdersList,
            icon: <NavIcon name="ic_cart" />,
            children: [
              {
                title: t('shared.ordersList'),
                path: Paths.LabOrdersList,
              },
              ...(isResourceAdmin
                ? [
                    {
                      title: t('shared.productsList'),
                      path: Paths.LabProductsList,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(currentRole.kind !== 'lab_regional_director'
      ? [
          {
            title: t('shared.groups'),
            path: Paths.LabGroupsLabs,
            icon: <NavIcon name="ic_group" />,
          },
          {
            title: t('shared.guideItems'),
            path: Paths.LabGuideItems,
            icon: <NavIcon name="ic_info" />,
          },
        ]
      : []),
    ...(isResourceAdmin
      ? [
          {
            title: t('shared.settings'),
            path: generatePath(Paths.LabEditLab, { id: String(labId) }),
            icon: <NavIcon name="ic_settings" />,
            ...(shouldSetupStripe && {
              rightIcon: <RoundedExclamation size={15} color={theme.palette.warning.main} />,
            }),
          },
        ]
      : []),
    {
      title: 'Aide',
      icon: <NavIcon name="ic_question" />,
      path: Paths.IntercomFAQ,
    },
  ];

  return <NavSection navItems={navItems} />;
}
