import { Box, Typography } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPhoneNumber } from 'redux/slices/auth';
import { Paths } from 'routes/paths';
import { LoginLayout } from '../LoginLayout';
import Form from './Form';

export const VerifyCode: FC = () => {
  const { t } = useTranslation();
  const phoneNumber = useSelector(selectPhoneNumber);

  return (
    <LoginLayout
      title={t('login.verify_code.enter_activation_code')}
      subtitle={
        <>
          <Typography sx={{ color: 'text.secondary' }} align="center">
            {t('login.verify_code.enter_sent_code', { phoneNumber })}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Typography sx={{ color: 'text.secondary' }} align="center">
              {t('login.verify_code.notYou')}
            </Typography>
            <Link to={Paths.LoginPhoneNumber} style={{ paddingLeft: '5px' }}>
              {t('login.verify_code.goBack')}
            </Link>
          </Box>
        </>
      }
      header={
        <Box
          component="img"
          sx={{ margin: '0 auto 20px auto' }}
          src="/assets/illustrations/login_illustration_code.svg"
        />
      }
    >
      <Form />
    </LoginLayout>
  );
};

export default VerifyCode;
