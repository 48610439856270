import { Box } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { SubtitleText } from './styles';

export const PharmacistButton: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SubtitleText>{t('login.youArePharmacist')}</SubtitleText>
      <Link to={Paths.LoginPhoneNumber}>{t('login.verify_email.pharmacist_login')}</Link>
    </Box>
  );
};
