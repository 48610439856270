import { ErrorDescription } from 'types/handled_error';

export const groupsHandledErrors: ErrorDescription[] = [
  {
    endpoint: 'groups/trades_tags',
    method: 'post',
    responseStatus: 422,
    error: 'tag_already_created',
  },
  {
    endpoint: 'groups/groups',
    method: 'patch',
    responseStatus: 409,
    error: 'group_name_taken',
  },
  {
    endpoint: 'groups/groups_pharmacies',
    method: 'post',
    responseStatus: 409,
    error: 'groups_pharmacy_already_exists',
  },
  {
    endpoint: 'groups/groups_pharmacies',
    method: 'post',
    responseStatus: 409,
    error: 'pharmacy_in_another_group',
  },
  {
    endpoint: /groups_pharmacies\/[\d]*\/_set_external_membership_id/,
    method: 'post',
    responseStatus: 409,
    error: 'code_already_taken',
  },
];
