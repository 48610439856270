import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

export const Loader: FC = () => (
  <Box
    alignItems="center"
    justifyContent="center"
    sx={{ display: 'flex', padding: '50px', width: '100%' }}
  >
    <CircularProgress size="30px" />
  </Box>
);
