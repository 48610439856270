import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { useCurrentGroupOrThrow, useCurrentRole } from 'hooks/slices';
import { useTranslation } from 'locales/i18n';
import { generatePath } from 'react-router';
import { Paths } from 'routes/paths';

export function GroupNavSection() {
  const { t } = useTranslation();

  const currentRole = useCurrentRole();
  const isResourceAdmin = currentRole && currentRole.kind === 'group_admin';
  const { is_newsfeed_enabled: isNewsfeedEnabled, id: groupId } = useCurrentGroupOrThrow();

  const navItems: NavListProps[] = [
    {
      title: t('navbar.dashboard'),
      path: Paths.GroupDashboard,
      icon: <NavIcon name="ic_mail" />,
    },
    ...(isNewsfeedEnabled
      ? [
          {
            title: t('shared.newsfeed'),
            path: Paths.GroupNewsfeedPosts,
            icon: <NavIcon name="ic_news" />,
          },
        ]
      : []),
    {
      title: t('shared.pharmacies'),
      path: Paths.GroupGroupsPharmacies,
      icon: <NavIcon name="ic_pharma" />,
    },
    {
      title: t('shared.invoices'),
      path: Paths.GroupClaims,
      icon: <NavIcon name="ic_invoice" />,
    },
    { title: t('shared.labs'), path: Paths.GroupGroupsLabs, icon: <NavIcon name="ic_lab" /> },
    { title: t('shared.trades'), path: Paths.GroupTrades, icon: <NavIcon name="ic_ecommerce" /> },
    ...(isResourceAdmin
      ? [
          {
            title: t('shared.settings'),
            path: generatePath(Paths.GroupGroupEdit, { id: String(groupId) }),
            icon: <NavIcon name="ic_settings" />,
          },
        ]
      : []),
    ...[
      {
        title: 'Aide',
        icon: <NavIcon name="ic_question" />,
        path: Paths.IntercomFAQ,
      },
    ],
  ];

  return <NavSection navItems={navItems} />;
}
