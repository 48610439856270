import {
  Group,
  PaginationMeta,
  Pharmacy,
  Role,
  Trade,
  TradeInstance,
  TradesInstanceStatus,
} from 'types/models';

export type GetAdminTradesInstancesParams = {
  page: number;
  trade_id?: number;
  query?: string;
  status?: TradesInstanceStatus;
};

export type AdminTradeInstance = TradeInstance & {
  pharmacy: Pharmacy & { group: Group | null };
  trade: Trade;
};

export type GetAdminTradesInstancesResponse = {
  trades_instances: AdminTradeInstance[];
  meta: PaginationMeta;
};

export type AdminTradeInstanceShow = TradeInstance & {
  pharmacy: Pharmacy & {
    role_to_impersonate: Role | null;
    group: Group | null;
  };
  trade: {
    role_to_impersonate: Role | null;
    lab_trade: Trade | null;
  };
};

export type GetAdminTradesInstanceResponse = {
  trades_instance: AdminTradeInstanceShow & { has_trades_requirements_instances: boolean };
};

export type DeleteAdminTradeInstanceResponse = {
  success: boolean;
};

export const expectedResultValues = [
  'mark_as_done',
  'mark_as_expired',
  'mark_as_pharmacy_not_panelist_client',
  'mark_as_missing_sellout_data_sharing_approval',
  'mark_as_missing_approval_or_not_client',
  'mark_as_missing_sellout_history',
  'reset',
] as const;

export type ExpectedResult = (typeof expectedResultValues)[number];

export const ErrorMessageValues = [
  'instance_not_found',
  'instance_not_subscribed',
  'sellout_panelist_request_already_exists',
  'trade_lgo_data_upload_disabled',
  'trade_without_sellout_requirement',
  'trade_without_trades_products',
  'pharmacy_without_lgo_activated',
] as const;

export type ErrorMessage = (typeof ErrorMessageValues)[number];

export type CreateFakePanelistRequestParams = {
  trades_instance_id: number;
  expected_result: ExpectedResult;
};

export type CreateFakePanelistRequestResponse = { error_message?: ErrorMessage; success?: boolean };
