import { Box } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { LoginLayout } from '../LoginLayout';
import { Form } from './Form';

export const VerifyPhoneNumber: FC = () => {
  const { t } = useTranslation();

  return (
    <LoginLayout
      title={t('login.verify_phone_number.verify_your_phone')}
      subtitle={t('login.verify_phone_number.will_send_sms')}
      header={
        <Box
          component="img"
          sx={{ margin: '0 auto 20px auto' }}
          src="/assets/illustrations/login_illustration_phone_number.svg"
        />
      }
    >
      <Form />
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Link to={Paths.LoginEmail}>{t('login.verify_phone_number.partner_access')}</Link>
      </Box>
    </LoginLayout>
  );
};

export default VerifyPhoneNumber;
