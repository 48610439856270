import { Box, BoxProps, Container, Stack, Typography } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import useSettings from 'hooks/useSettings';
import { ReactNode, useState } from 'react';
import { Loader } from './Loader';
import Page from './Page';
import { FillingBox } from './filling/FillingBox';
import { GoBackLink } from './link/GoBackLink';
import TableMoreMenu from './table/TableMoreMenu/TableMoreMenu';

interface Props extends BoxProps {
  pageTitle: string;
  pageTitleBadge?: ReactNode;
  pageSubtitle?: string;
  goBackLink?: { title: string; url: string };
  canGoBack?: boolean;
  topRightButtons?: ReactNode;
  moreMenuActions?: ReactNode;
  isLoading?: boolean;
  pageInfoSection?: ReactNode;
  onPageTitleClick?: VoidFunction;
}

export default function PageContainer({
  pageTitle,
  pageTitleBadge,
  pageSubtitle,
  goBackLink,
  topRightButtons,
  moreMenuActions,
  isLoading,
  pageInfoSection,
  onPageTitleClick,
  children,
  canGoBack,
}: Props) {
  const { themeStretch } = useSettings();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const isDesktop = useResponsive('up', 'lg');
  // margins handled by the upper level layout
  const reservedSpace = isDesktop ? 48 : 112;

  return (
    <Page title={pageTitle} style={{ height: `calc(100vh - ${reservedSpace}px)`, display: 'flex' }}>
      <Container
        maxWidth={themeStretch ? false : 'xl'}
        style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mb: 3 }}
        >
          <Box>
            {(goBackLink || canGoBack) && (
              <GoBackLink title={goBackLink?.title} url={goBackLink?.url} canGoBack={canGoBack} />
            )}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="h3"
                onClick={onPageTitleClick}
                sx={{ '&:hover': { cursor: onPageTitleClick ? 'pointer' : 'default' } }}
              >
                {pageTitle}
              </Typography>
              {pageTitleBadge}
            </Stack>
            {pageSubtitle && (
              <Typography variant="h5" color="GrayText">
                {pageSubtitle}
              </Typography>
            )}
          </Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
            {topRightButtons}
            {moreMenuActions && (
              <TableMoreMenu
                open={isOpen}
                anchorEl={anchorEl}
                onOpen={(event) => setAnchorEl(event.currentTarget)}
                onClose={() => setAnchorEl(null)}
                actions={moreMenuActions}
              />
            )}
          </Stack>
        </Stack>
        {pageInfoSection}
        <FillingBox>{isLoading ? <Loader /> : children}</FillingBox>
      </Container>
    </Page>
  );
}
