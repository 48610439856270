import { Loader } from 'components/Loader';
import { useCurrentUser } from 'hooks/slices';
import { useMountEffect } from 'hooks/useMountEffect';
import { useOnLogin } from 'hooks/useOnLogin';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const FromIdentityProvider = () => {
  const [searchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const onLogin = useOnLogin();

  const authToken = searchParams.get('auth_token');
  const alreadyAuthenticated = !!currentUser;

  useMountEffect(() => {
    if (alreadyAuthenticated) return navigate('/');

    if (!authToken) return navigate('/login');
    onLogin(authToken);
  });

  return <Loader />;
};

export default FromIdentityProvider;
