import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledLink } from './styles';

type LocationState = {
  canGoBack: boolean;
} | null;
interface Props {
  title?: string;
  url?: string;
  canGoBack?: boolean;
}

export const GoBackLink = ({ title = '', url = '', canGoBack }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const typedState = state as LocationState;

  const handleGoBack = () => {
    if (canGoBack || typedState?.canGoBack) navigate(-1);
    else navigate(url);
  };

  return (
    <StyledLink onClick={handleGoBack}>
      <Stack direction="row" alignItems="center">
        <ArrowBackIcon fontSize="small" />{' '}
        {canGoBack ? t('shared.goBack') : t('shared.goBackTo', { title: title.toLowerCase() })}
      </Stack>
    </StyledLink>
  );
};
