export enum Paths {
  // Pharmacy
  PharmacyClaims = '/pharmacy/claims',
  PharmacyClaim = '/pharmacy/claims/:id',
  PharmacyClaimNewPage = '/pharmacy/claims/new-claim',
  PharmacyTrades = '/pharmacy/trades',
  PharmacyTrade = '/pharmacy/trades/:id',
  PharmacyTradeNew = '/pharmacy/trades/new',
  PharmacyOrders = '/pharmacy/orders',
  PharmacyOrder = '/pharmacy/orders/:id',
  PharmacyNewOrder = '/pharmacy/orders/new',
  PharmacyOrdersRestocking = '/pharmacy/orders/restocking',
  PharmacyLabsPharmacies = '/pharmacy/labs_pharmacies',
  PharmacyLabsPharmacy = '/pharmacy/labs_pharmacies/:id',
  PharmacyLabsDiscussions = '/pharmacy/labs_discussions',
  PharmacyLabDiscussion = '/pharmacy/labs_discussions/:id',
  PharmacyEditCurrentPharmacy = '/user/pharmacy',
  PharmacyStripe = '/pharmacy/stripe',
  PharmacyStripeBillingForm = '/pharmacy/stripe/billing',
  PharmacyLgoSetting = '/pharmacy/lgo_setting',
  PharmacyExpiredProductsRequest = '/pharmacy/claims/expired_products_request/:id',
  PharmacyPharmacyEdit = '/pharmacy/:id/edit',
  PharmacyCompensations = '/pharmacy/compensations',
  // Group
  GroupDashboard = '/group/dashboard',
  GroupNewsfeedPosts = '/group/newsfeed',
  GroupNewsfeedPostEdit = '/group/newsfeed/:id/edit',
  GroupNewsfeedPostNew = '/group/newsfeed/new',
  GroupNewsfeedPost = '/group/newsfeed/:id',
  GroupGroupsPharmacies = '/group/groups_pharmacies',
  GroupGroupsPharmaciesEdit = '/group/groups_pharmacies/:id/edit',
  GroupPharmacyLists = '/group/pharmacy_lists',
  GroupPharmacyListNew = '/group/pharmacy_lists/new',
  GroupPharmacyList = '/group/pharmacy_lists/:id',
  GroupClaims = '/group/claims',
  GroupClaim = '/group/claims/:id',
  GroupGroupsLabs = '/group/groups_labs',
  GroupGroupsLabsEdit = '/group/groups_labs/:id/edit',
  GroupTrades = '/group/trades',
  GroupTrade = '/group/trades/:id',
  GroupTradeNew = '/group/trades/new',
  GroupTradeEdit = '/group/trades/:id/edit',
  GroupGroupEdit = '/group/:id/edit',
  // Lab
  LabTradesDashboard = '/lab/trades/dashboard',
  LabTrades = '/lab/trades/list',
  LabClaimsDashboard = '/lab/claims/dashboard',
  LabClaimsList = '/lab/claims/list',
  // Warning: We will have to change the path below to /lab/claims/:id, but preserving the redirection for old secured links
  LabClaim = '/lab/claims/list/:id',
  // Kept for backward compatibility with sent emails
  LabSecuredClaimOld = '/lab/claims/:id',
  SecuredLabClaim = '/secured/claims/:id',
  LabClaimNew = '/lab/claims/new',
  LabExpiredProductsRequestsDashboard = '/lab/expired_products_requests/dashboard',
  LabExpiredProductsRequestsList = '/lab/expired_products_requests/list',
  LabExpiredProductsRequest = '/lab/expired_products_requests/:id',
  LabLabsPharmacies = '/lab/pharmacies/list',
  LabLabsPharmacy = '/lab/pharmacies/list/:id',
  LabGroupsLabs = '/lab/groups_labs',
  LabGroupsLabEdit = '/lab/groups_labs/:id/edit',
  LabGuideItems = '/lab/guide_items',
  LabGuideItemNew = '/lab/guide_items/new',
  LabGuideItemEdit = '/lab/guide_items/:id/edit',
  LabEditLab = '/lab/:id/edit',
  LabRegionNew = '/lab/regions/new',
  LabRegionEdit = '/lab/regions/:id/edit',
  LabTopicNew = '/lab/topics/new',
  LabTopicEdit = '/lab/topics/:id/edit',
  LabSecuredAccessTrades = '/securedaccess/trades',
  LabSecuredAccessDashboard = '/securedaccess/dashboard',
  LabPharmacyLists = '/lab/pharmacies/lists',
  LabPharmacyListNew = '/lab/pharmacies/lists/new',
  LabPharmacyList = '/lab/pharmacies/lists/:id',
  LabOrdersList = '/lab/orders',
  LabOrder = '/lab/orders/:id',
  LabProductsList = '/lab/products',
  LabMasterTrades = '/lab/trades/master_trades',
  LabMasterTradeNew = '/lab/trades/master_trades/new',
  LabMasterTrade = '/lab/trades/master_trades/:id',

  // Admin
  AdminHome = '/admin/home',
  AdminImpersonate = '/admin/tools/impersonate',
  AdminPharmaciesMerge = '/admin/tools/pharmacies/merge',
  AdminDatamatrix = '/admin/tools/datamatrix',
  AdminSelloutPanelistGenerator = '/admin/tools/sellout_panelist_generator',
  AdminIngestionsHub = '/admin/tools/ingestions',
  AdminNewIngestion = '/admin/tools/ingestions/new-ingestion',
  AdminInboundMails = '/admin/inbound-mails',
  AdminOrders = '/admin/orders',
  AdminOrder = '/admin/orders/:id',
  AdminOrderEdit = '/admin/orders/:id/edit',
  AdminOrderNew = '/admin/orders/new',
  AdminLabTrades = '/admin/lab_trades',
  AdminExpiredProductsRequests = '/admin/expired_products_requests',
  AdminExpiredProductsRequest = '/admin/expired_products_requests/:id',
  AdminClaims = '/admin/invoices',
  AdminClaim = '/admin/invoices/:id',
  AdminTrades = '/admin/trades',
  AdminTrade = '/admin/trades/:id',
  AdminTradesInstances = '/admin/trades/instances',
  AdminTradeInstance = '/admin/trades/instances/:id',
  AdminLabs = '/admin/labs',
  AdminLabNew = '/admin/labs/new',
  AdminLab = '/admin/labs/:id',
  AdminMarketingTools = '/admin/tools/marketing_tools',
  AdminMarketingToolNew = '/admin/tools/marketing_tools/new',
  AdminMarketingTool = '/admin/tools/marketing_tools/:id',
  AdminGroups = '/admin/groups',
  AdminGroupNew = '/admin/groups/new',
  AdminGroup = '/admin/groups/:id',
  AdminPharmacies = '/admin/pharmacies',
  AdminPharmacyNew = '/admin/pharmacies/new',
  AdminPharmacy = '/admin/pharmacies/:id',
  AdminNewsfeedPosts = '/admin/newsfeed_posts',
  AdminNewsfeedPost = '/admin/newsfeed_posts/:id',
  AdminNewsfeedPostNew = '/admin/newsfeed_posts/new',
  AdminNewsfeedPostEdit = '/admin/newsfeed_posts/:id/edit',
  AdminClaimRelaunchPlans = '/admin/claim_relaunch_plans',
  AdminClaimRelaunchPlanNew = '/admin/claim_relaunch_plans/new',
  AdminClaimRelaunchPlanEdit = '/admin/claim_relaunch_plans/:id/edit',
  AdminUsers = '/admin/users',
  AdminUser = '/admin/users/:id',
  AdminUserNew = '/admin/users/new',
  AdminUserEdit = '/admin/users/:id/edit',
  AdminGroupsLabs = '/admin/tools/groups_labs',
  AdminCheckNeedingPharmacies = '/admin/tools/check_needing_pharmacies',
  AdminReferentialPharmacies = '/admin/tools/referential_pharmacies',

  //Onboarder
  OnboarderPharmacies = '/onboarder/pharmacies',
  OnboarderPharmacyEdit = '/onboarder/pharmacies/:id/edit',

  // Common
  EditCurrentUser = '/user/profile',
  LoginPhoneNumber = '/login/phone_number',
  LoginCode = '/login/code',
  LoginEmail = '/login/email',
  LoginInvitation = '/login/invitation',
  LoginFromToken = '/login/from_token',
  LoginFromIdentityProvider = '/login/from_identity_provider',
  PasswordReset = '/login/password/reset',
  PasswordEdit = '/login/password/edit',
  NotFound = '/404',
  Unauthorized = '/403',
  InvalidToken = '/498',
  OnboardingPharmacistOrLab = '/onboarding/pharmacist_or_lab',
  OnboardingLabUnavailable = '/onboarding/lab_unavailable',
  OnboardingCreateProfile = '/onboarding/create_profile',
  OnboardingSearchPharmacy = '/onboarding/search_pharamacy',
  OnboardingCreatePharmacy = '/onboarding/create_pharmacy',
  OnboardingJoinPharmacy = '/onboarding/join_pharmacy',
  OnboardingVerifyEmail = '/onboarding/verify_email',
  OnboardingVerifyAccount = '/onboarding/verify_account',
  NoAccess = '/no-access',
  StripeState = '/stripe/state',

  // External
  IntercomFAQ = 'https://intercom.help/faks/fr',
}
