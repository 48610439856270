import { includes, isEmpty, isNil } from 'lodash';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { Pharmacy, Role, User } from 'types/models';
import { PharmacyVerificationState, getPharmacyVerificationState } from 'utils/onboarding';

type Props = {
  component: ReactNode;
  pathname: string;
  pharmacy: Pharmacy | null;
  role: Role | null;
  user: User | null;
};

export const OnboardingStepCheck: FC<Props> = ({ component, pathname, pharmacy, role, user }) => {
  const pharmacyVerificationState = getPharmacyVerificationState(
    pharmacy?.verified || false,
    role?.kind
  );

  if (!user) return <Navigate to={Paths.LoginPhoneNumber} />;

  // Hacky solution to mark profile creation step
  const isProfileCreated = !isEmpty(user?.first_name);

  // Prevents the user from skipping onboarding steps
  if (
    !isProfileCreated &&
    !includes(
      [
        Paths.OnboardingCreateProfile,
        Paths.OnboardingPharmacistOrLab,
        Paths.OnboardingLabUnavailable,
      ],
      pathname
    )
  )
    return <Navigate to={Paths.OnboardingPharmacistOrLab} />;

  // Prevents the user from going back on the onboarding steps
  if (
    isProfileCreated &&
    isNil(role) &&
    !includes(
      [
        Paths.OnboardingSearchPharmacy,
        Paths.OnboardingCreatePharmacy,
        Paths.OnboardingJoinPharmacy,
      ],
      pathname
    )
  )
    return <Navigate to={Paths.OnboardingSearchPharmacy} />;

  if (
    pharmacyVerificationState === PharmacyVerificationState.email &&
    Paths.OnboardingVerifyEmail !== pathname
  )
    return <Navigate to={Paths.OnboardingVerifyEmail} />;

  if (
    pharmacyVerificationState === PharmacyVerificationState.phoneBack &&
    Paths.OnboardingVerifyAccount !== pathname
  )
    return <Navigate to={Paths.OnboardingVerifyAccount} />;
  return <>{component}</>;
};
