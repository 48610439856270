import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';

export const useOnErrorNavigation = () => {
  const navigate = useNavigate();

  const handleErrorNavigation = (status?: number) => {
    if (status === 403) navigate(Paths.Unauthorized);
    if (status === 404) navigate(Paths.NotFound);
  };
  return { handleErrorNavigation };
};
