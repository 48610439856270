import { Stack } from '@mui/material';
import { useGetUserQuery, useIndexQuery, useStatisticsQuery } from 'api';
import { NewBadge } from 'components/NewBadge';
import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { NotifBadgeIcon } from 'components/notifBadgeIcon/NotifBadgeIcon';
import { useCurrentPharmacyOrThrow, useCurrentRole } from 'hooks/slices';
import { useTranslation } from 'locales/i18n';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { selectUserToken } from 'redux/slices/auth';
import { Paths } from 'routes/paths';

export function PharmacyNavSection() {
  const { t } = useTranslation();
  const authToken = useSelector(selectUserToken);
  const {
    id: pharmacyId,
    is_order_enabled: isOrderEnabled,
    is_lab_chat_enabled: isLabChatEnabled,
  } = useCurrentPharmacyOrThrow();
  const currentRole = useCurrentRole();
  const isResourceAdmin = currentRole && currentRole.kind === 'pharmacy_owner';

  const { data: awaitingPharmacyActionCountResponse } = useIndexQuery(
    'pharmacies/trades_instances/_count_awaiting_actions',
    undefined,
    {
      enabled: true,
    }
  );

  const { data: pharmacySettingsData } = useIndexQuery(
    'pharmacies/settings/current_pharmacy_setting',
    {}
  );

  const { data: pharmacyCaseStatisticsData } = useStatisticsQuery(
    'pharmacies/cases',
    {},
    { enabled: true }
  );

  const { data: userInfoData } = useGetUserQuery({
    enabled: !!authToken,
  });

  const notSeenCount = pharmacyCaseStatisticsData?.statistics.unseen_cases_count;
  const notSeenMessagesDiscussionCount = userInfoData?.meta?.not_seen_discussions_count;
  const notConfirmedOrdersCount = userInfoData?.meta?.not_confirmed_orders_count;
  const notSeendOrdersMessagesCount = userInfoData?.meta?.not_seen_orders_messages_count;
  const ordersNotificationCount =
    (notConfirmedOrdersCount || 0) + (notSeendOrdersMessagesCount || 0);
  const isCentralizedCompensationTrackingEnabled =
    pharmacySettingsData?.pharmacy_setting.is_centralized_compensation_tracking_enabled;

  const navItems: NavListProps[] = [
    {
      title: t('navbar.trades'),
      path: Paths.PharmacyTrades,
      icon: <NavIcon name="ic_booking" />,
      ...(awaitingPharmacyActionCountResponse?.count && {
        rightIcon: <NotifBadgeIcon count={awaitingPharmacyActionCountResponse.count} />,
      }),
    },
    {
      title: t('navbar.claims'),
      path: Paths.PharmacyClaims,
      icon: <NavIcon name="ic_invoice" />,
      ...(notSeenCount && { rightIcon: <NotifBadgeIcon count={notSeenCount} /> }),
    },
    ...(isLabChatEnabled
      ? [
          {
            title: (
              <Stack direction="row" spacing={1}>
                <div>{t('navbar.discussions')}</div>
                <NewBadge />
              </Stack>
            ),
            path: Paths.PharmacyLabsDiscussions,
            icon: <NavIcon name="ic_mail" />,
            ...(notSeenMessagesDiscussionCount && {
              rightIcon: <NotifBadgeIcon count={notSeenMessagesDiscussionCount} />,
            }),
          },
        ]
      : []),
    ...(isOrderEnabled
      ? [
          {
            title: t('navbar.orders'),
            path: Paths.PharmacyOrders,
            icon: <NavIcon name="ic_cart" />,
            ...(ordersNotificationCount && {
              rightIcon: <NotifBadgeIcon count={ordersNotificationCount} />,
            }),
          },
        ]
      : []),
    ...(isCentralizedCompensationTrackingEnabled
      ? [
          {
            title: (
              <Stack direction="row" spacing={1}>
                <div>{t('navbar.compensations')}</div>
                <NewBadge />
              </Stack>
            ),
            path: Paths.PharmacyCompensations,
            icon: <NavIcon name="ic_euro" />,
          },
        ]
      : []),
    {
      title: t('navbar.labs'),
      path: Paths.PharmacyLabsPharmacies,
      icon: <NavIcon name="ic_lab" />,
    },
    ...(isResourceAdmin
      ? [
          {
            title: t('shared.settings'),
            path: generatePath(Paths.PharmacyPharmacyEdit, { id: String(pharmacyId) }),
            icon: <NavIcon name="ic_settings" />,
          },
        ]
      : []),
    {
      title: 'Aide',
      icon: <NavIcon name="ic_question" />,
      path: Paths.IntercomFAQ,
    },
  ];

  return <NavSection navItems={navItems} />;
}
