import { useCreateMutation } from 'api';
import { Loader } from 'components/Loader';
import { useCurrentUser } from 'hooks/slices';
import { useMountEffect } from 'hooks/useMountEffect';
import { useOnLogin } from 'hooks/useOnLogin';
import { Trans } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const FromToken = () => {
  const [searchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const authenticateWithTokenMutation = useCreateMutation('authenticate_with_sign_in_token');
  const onLogin = useOnLogin();

  const signInToken = searchParams.get('sign_in_token');
  const alreadyAuthenticated = !!currentUser;

  useMountEffect(() => {
    if (alreadyAuthenticated) throw new Error('Should not happen');

    if (!signInToken) return navigate('/login');

    authenticateWithTokenMutation.mutateAsync({ sign_in_token: signInToken }).then((data) => {
      if (data.success) {
        onLogin(data.auth_token);
        toast.success(
          <Trans
            i18nKey={'autologin.successfullyConnectedAs'}
            components={[<strong />]}
            values={{ userName: data.user_full_name }}
          />
        );
      } else {
        return navigate('/login');
      }
    });
  });

  return <Loader />;
};

export default FromToken;
